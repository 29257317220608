import type { Model } from "replicate";
import { useReducedMotion } from "framer-motion";
import Marquee from "react-fast-marquee";
import { InlineModelCard } from "./model-card";

export function CommunityMarquee({
  top,
  bottom,
}: {
  top: Model[];
  bottom: Model[];
}) {
  const reducedMotion = useReducedMotion();
  return (
    <div className="space-y-8">
      <Marquee
        className="py-1"
        play={!reducedMotion}
        speed={20}
        direction="right"
        pauseOnHover
      >
        {top.map((model) => {
          return (
            <a
              tabIndex={-1}
              href={model.url}
              key={model.owner + model.name}
              className="h-40 max-w-lg mx-4 block marquee-model-card"
            >
              <InlineModelCard
                loading="lazy"
                key={model.owner + model.name}
                model={model}
              />
            </a>
          );
        })}
      </Marquee>
      <Marquee
        className="py-1"
        play={!reducedMotion}
        speed={20}
        direction="left"
        pauseOnHover
      >
        {bottom.map((model) => {
          return (
            <a
              tabIndex={-1}
              href={model.url}
              key={model.owner + model.name}
              className="h-40 max-w-lg mx-4 block marquee-model-card"
            >
              <InlineModelCard
                loading="lazy"
                key={model.owner + model.name}
                model={model}
              />
            </a>
          );
        })}
      </Marquee>
    </div>
  );
}
